'use client'

import extractOriginalReferrer from '@lib/browsers/cookies/extractOriginalReferrer'
import { getUtmParamsFromSearchParams } from '@lib/browsers/utm/getUtmParamsFromSearchParams'
import type { UTMParameters } from '@lib/browsers/utm/types'
import type { ReadonlyURLSearchParams } from 'next/navigation'

export const getQueryParamsToPersistInCookie = (
  cookies: { urlParams: UTMParameters & { referrer?: string } },
  searchParams: ReadonlyURLSearchParams | URLSearchParams | null,
): UTMParameters & { referrer?: string } => {
  const oldCookie = cookies.urlParams ? cookies.urlParams : {}
  // if there are any utm parameters in the url, we want to wipe all prev. utm params, and replace them with the new ones
  const utmParamsFromUrl = getUtmParamsFromSearchParams(searchParams)
  const newUtmParams = Object.values(utmParamsFromUrl).some(Boolean)
    ? utmParamsFromUrl
    : {}

  const newCookie = {
    ...oldCookie,
    ...newUtmParams,
    ...extractOriginalReferrer(),
  }

  return newCookie
}
