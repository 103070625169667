// TODO: https://linear.app/withpower/issue/POW-384/fix-could-not-find-a-declaration-file-for-module-for-segment-analytics
// @ts-ignore
import segmentPlugin from '@analytics/segment'
import type { FilterState } from '@components/app/SiteStaffPortal/RegistryPage/SearchAndFilterBar/FilterModal/types'
import { extractSegmentAnonymousIdFromCookie } from '@lib/browsers/cookies/extractSegmentAnonymousIdFromCookie'
import type { SegmentUTMContext } from '@lib/browsers/utm/types'
import { isProcessClientSide } from '@lib/config/runtime'
import isNotBot from '@lib/tracking/analytics-plugin-is-not-bot'
import { type ExtendedFormDataPoint } from '@lib/types/FormData'
import getUserIdTokenFromJwtCookie from '@modules/auth/jwt/getUserIdTokenFromJwtCookie'
import Analytics from 'analytics'
import type { ReadonlyURLSearchParams } from 'next/navigation'
import { getPageTrackPropsFromRouter } from './getPageTrackPropsFromRouter'
import getSegmentUserIdFromCookie from './getSegmentUserIdFromCookie'
import getSegmentUtmParamsFromCookie from './getSegmentUtmParamsFromCookie'
import { type TrackingEvents } from './TrackingEvents'
import { type TrackingSources } from './TrackingSources'

export const analytics = Analytics({
  app: 'withpower',
  debug:
    process.env.NODE_ENV !== 'production' ||
    process.env.CURRENT_ENV === 'preview',
  plugins: [
    isNotBot(),
    segmentPlugin({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? '',
    }),
  ],
})

/**
 * Add `window.analytics` for Segment tracking.
 * Add `window.hj` for HotJar event tracking.
 * See https://help.hotjar.com/hc/en-us/articles/4405109971095-Events-API-Reference
 */
declare global {
  interface Window {
    analytics?: typeof Analytics
    hj?: (mustBeEvent: 'event', eventName: string) => void
  }
}

// Only use pageview reserve word properties for their intended meaning
// Segment always expects path to be a page’s URL path https://segment.com/docs/connections/spec/page/
export const trackPageview = (
  pathname: string,
  searchParams: URLSearchParams | ReadonlyURLSearchParams | null,
  campaign?: SegmentUTMContext,
) => {
  const pageProps = getPageTrackPropsFromRouter(pathname, searchParams)
  analytics.page(pageProps, { campaign })
}

/**
 * Only use reserve word properties for their intended meaning (revenue!) https://segment.com/docs/connections/spec/track/#properties
 */
export type SegmentReserveWordProperties = {
  currency?: string
  revenue?: number
  value?: number
}
type AvoidSegmentReserveWordProperties = {
  currency?: never
  revenue?: never
  value?: never
}

export type IntegrationOptions = {
  integrations: {
    [key: string]: boolean
  }
}

/**
 * Please add context-specific properties for events. Don't be afraid of being too verbose - we want to optimize for
 * insight discovery when looking at the logs!
 */
export type TrackingProperties = {
  actionSource?: string
  clinicalLocationId?: number
  content_category?: string // Segement is unable to convert to snake_case which is required by Facebook
  context?: TrackContext
  doctorSlug?: string
  durationInSeconds?: number
  email?: string
  index?: number
  nctId?: string
  path?: string
  patientIsQualified?: boolean
  patientTrialSearchCondition?: string
  patientTrialSearchPageNumber?: string
  patientTrialSearchResultCount?: string
  phoneRelayDigitsInput?: string
  products?: {
    product_id: string
    quantity: number
  }[]
  productTourStep?: number
  questionnaireAttribute?: string
  questionnaireHasMonetizedTrialSite?: boolean
  questionnaireInputValue?: ExtendedFormDataPoint
  questionnaireOptionCount?: number
  questionnaireOptionLabels?: string[]
  questionnaireOptionValues?: string[]
  source?: TrackingSources
  sspFilterState?: FilterState
  sspFilterStateSource?: string
  sspReferralsPageReferralCount?: number
  sspReferralsSortApplied?: boolean
  sspRegistryPatientResults?: number[]
  stringifiedUrlParams?: string
  traits?: Traits
  trialApplicationId?: number
  trialApplicationIdToken?: string
  trialLocationId?: number
  trialLocationIds?: number[]
  trialSlug?: string
  type?: string | number
  userId?: string
  val?: string | number
} & AvoidSegmentReserveWordProperties

// https://segment.com/docs/connections/destinations/catalog/google-analytics/#utm-parameters
export type TrackContext = {
  campaign?: {
    content?: string
    medium: string
    name: string
    referrer?: string
    source: string
    term?: string
  }
  traits?: Traits
}

/**
 * Non-isomorphic, client side tracking for outbound analytic events to Segemnt.
 */
export const trackEvent = (
  eventName: TrackingEvents,
  properties?: TrackingProperties,
  segmentReserveProperties?: SegmentReserveWordProperties,
  options?: IntegrationOptions,
) => {
  const search =
    typeof window !== 'undefined' && window ? window.location?.search ?? '' : ''
  const path =
    typeof window !== 'undefined' && window
      ? window.location?.pathname ?? '/'
      : '/'
  const urlParams = new URLSearchParams(search)
  const searchParamsObject = Object.fromEntries(urlParams.entries())

  const segmentUtmTrackingContext = getSegmentUtmParamsFromCookie()

  // extract userIdToken as userId from JWT cookie, identify the user if we haven't already
  // FIXME: could have impersonation attacks but this is just event tracking so should be ok
  const userIdToken = getUserIdTokenFromJwtCookie()
  if (userIdToken) {
    const segmentUserIdFromCookie = getSegmentUserIdFromCookie()
    if (userIdToken !== segmentUserIdFromCookie) {
      identifyUser({ userId: userIdToken }) // make sure we tell analytics we have a logged in user
    }
  }

  const propertiesWithPath = {
    ...searchParamsObject,
    ...properties,
    path,
  }

  const allOptions = {
    ...segmentUtmTrackingContext,
    ...defaultIntegrationOptions,
    ...options,
  }
  const allProperties = {
    ...propertiesWithPath,
    ...segmentReserveProperties,
  }
  analytics.track(eventName, allProperties, allOptions)

  if (isHotJarPresent() && typeof window.hj === 'function') {
    // We don't get props with HotJar, just event names that must be A-Za-z0-9\-_
    window.hj('event', underscoreForHotJar(eventName))
  }
}

// we want to avoid sending all events to facebook
export const defaultIntegrationOptions: IntegrationOptions = {
  integrations: {
    'Facebook Conversions API': false,
    'Facebook Pixel': false,
  },
}

export const sendEventToFacebook: IntegrationOptions = {
  integrations: {
    'Facebook Conversions API': true,
    'Facebook Pixel': true,
  },
}

export const emptySegmentReserveProperties = {}
/**
 * Isomorphic event tracking for outbound analytic events to Segment and Facebook.
 *
 * @param eventName
 * @param properties
 * @param integrationOptions
 */
export function trackEventWithFacebook(
  eventName: TrackingEvents,
  properties?: TrackingProperties,
  integrationOptions?: IntegrationOptions,
) {
  return trackEvent(eventName, properties, emptySegmentReserveProperties, {
    ...sendEventToFacebook,
    ...integrationOptions,
  })
}

const isHotJarPresent = () => {
  return (
    isProcessClientSide() &&
    typeof window !== 'undefined' &&
    window &&
    window.hj &&
    typeof window.hj === 'function'
  )
}

// https://segment.com/docs/connections/spec/identify/#traits
type Traits = {
  address?: {
    city?: string // newyork (no spaces or punctuation)
    country?: string
    postalCode?: string // Use lowercase with no spaces and no dash. Use only the first 5 digits for U.S. zip codes.
    state?: string // Use the 2-character ANSI abbreviation code in lowercase.
    street?: string
  }
  age?: number
  birthday?: Date
  email?: string
  firstName?: string //Normalized format: mary
  gender?: string // f for female,  m for male
  id?: string
  lastName?: string
  name?: string
  phone?: string // Normalized format: 16505551212
  title?: string
  username?: string
  zipcode?: string
}

/**
 * Get the segment anonymous id by either looking at the cookie or falling back to the client side code
 * @see https://segment.com/docs/connections/spec/best-practices-identify/
 */
export const getUserSegmentAnonymousId = () => {
  return extractSegmentAnonymousIdFromCookie() ?? analytics.user().anonymousId
}

/**
 *  Every identify call must have a User ID or an Anonymous ID. This allows us to attribute anonymous users to their immutable user id
 */
export const identifyUser = ({
  anonymousId,
  traits,
  userId,
}: {
  anonymousId?: string
  traits?: Traits
  userId: string
}) => {
  if (isProcessClientSide()) {
    analytics.identify(userId, traits)
  } else if (anonymousId) {
    // Passing in the anonymousId through traits is an undocumented feature described here: https://github.com/segmentio/analytics-react-native/issues/125#issuecomment-620311000
    // This doesn't actually work to override the anonymousId, but is still useful to identify the userId to _an_ anonymousId
    // The anonymousId going forward on the server side would be linked to this userId
    analytics.identify(userId, { ...traits, anonymousId })
  }
}

/**
 * Replace non-word and non-dash chars with underscores for HotJar event names.
 *
 * @param eventName TrackingEvent name with spaces
 */
export function underscoreForHotJar(eventName: string) {
  return eventName.replace(/[^\w\-]/gi, '_')
}
