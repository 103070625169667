import extractTrackingOptionsFromUtmParams, {
  URL_PARAM_STORAGE_KEY,
} from '@lib/browsers/cookies/extractTrackingOptionsFromUtmParams'
import type { TrialApplicationUtmParams } from '@modules/patientTrialApplications/helpers/tracking/getTrialApplicationUtmParamsFromCookie'
import omitBy from 'lodash/omitBy'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export default function getSegmentUtmParamsFromCookie() {
  const urlParamsObject: TrialApplicationUtmParams | undefined = cookies.get(
    URL_PARAM_STORAGE_KEY,
  )
  const allUtmParams = urlParamsObject
    ? extractTrackingOptionsFromUtmParams(urlParamsObject)
    : { context: {} }
  return {
    context: {
      campaign: omitBy(allUtmParams?.context.campaign, (val) => !val),
    },
  }
}
