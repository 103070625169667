import omitBy from 'lodash/omitBy'
import { object, string, type TypeOf } from 'zod'
import type { UTMParameters } from '../utm/types'

export const UTMParamValidator = object({
  referrer: string().optional(),
  utm_campaign: string().optional(),
  utm_content: string().optional(),
  utm_medium: string().optional(),
  utm_name: string().optional(),
  utm_source: string().optional(),
  utm_term: string().optional(),
})
export type UtmParams = TypeOf<typeof UTMParamValidator>

export type UrlParamCookie = { urlParams?: any }

export default function extractUtmParamsFromCookie(
  cookies?: UrlParamCookie,
): UTMParameters {
  if (!cookies) {
    return {}
  }
  // Strip GET params to avoid recursion
  const referrer = cookies.urlParams?.referrer
    ? cookies.urlParams.referrer.split('?')[0]
    : undefined

  const allUtmParams = {
    referrer,
    utm_campaign: cookies.urlParams?.utm_campaign,
    utm_content: cookies.urlParams?.utm_content,
    utm_medium: cookies.urlParams?.utm_medium,
    utm_name: cookies.urlParams?.utm_name,
    utm_source: cookies.urlParams?.utm_source,
    utm_term: cookies.urlParams?.utm_term,
  }

  // Only push UTM params if they exist on the cookie
  return omitBy(allUtmParams, (val) => !val)
}
