import { cloudflareImageUrl } from '@lib/cloudflare/cloudflareImageUrl'
import {
  SupplyFaviconNotif320,
  SupplyFavicon320,
  PatientFaviconNotif320,
  PatientFavicon320,
} from '@modules/images/shared/favicons'
import Head from 'next/head'

interface FaviconProps {
  inSSP: boolean
}

export function getFaviconPath({
  inSSP,
  withNotifications = false,
}: {
  inSSP: boolean
  withNotifications?: boolean
}) {
  const imageId = getFaviconImageId({ inSSP, withNotifications })
  return cloudflareImageUrl(imageId)
}

function getFaviconImageId({
  inSSP,
  withNotifications = false,
}: {
  inSSP: boolean
  withNotifications?: boolean
}) {
  if (inSSP) {
    return withNotifications ? SupplyFaviconNotif320 : SupplyFavicon320
  } else {
    return withNotifications ? PatientFaviconNotif320 : PatientFavicon320
  }
}

export default function Favicon({ inSSP }: FaviconProps) {
  const faviconPath = getFaviconPath({ inSSP })
  return (
    <Head>
      <link href={faviconPath} rel='icon' />
    </Head>
  )
}
