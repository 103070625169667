'use client'

import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { faClose } from '@fortawesome/pro-regular-svg-icons/faClose'
import type { MouseEvent, ReactNode } from 'react'
import { createRef, useEffect } from 'react'
import type { TextStyle } from '../Text/fontStyles'

export type ModalSize = 'Full' | 'Large' | 'Medium' | 'Small' | 'Tiny'

interface ModalProps {
  bodyClassName?: string
  children: ReactNode | ReactNode[]
  className?: string
  closeContainerClassName?: string
  footer?: ReactNode | ReactNode[]
  footerClassName?: string
  headerContainerClassName?: string
  onClose?: () => void
  preventClose?: boolean
  sectionClassName?: string
  showHeader?: boolean
  size?: ModalSize
  skipFocusOnOpen?: boolean
  title?: ReactNode | ReactNode[]
  titleContainerClassName?: string
  titleTextClassName?: string
  titleTextStyleName?: TextStyle
  visible?: boolean
  wrapperClassName?: string
}

export default function Modal({
  bodyClassName,
  children,
  className,
  closeContainerClassName,
  footer,
  footerClassName,
  headerContainerClassName,
  onClose,
  preventClose = false,
  sectionClassName,
  showHeader = true,
  size = 'Medium',
  skipFocusOnOpen = false,
  title,
  titleContainerClassName,
  titleTextClassName,
  titleTextStyleName,
  visible = false,
  wrapperClassName,
}: ModalProps) {
  const modalRef = createRef<HTMLDivElement>()

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !preventClose) {
        onClose && onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onClose])

  useEffect(() => {
    if (visible) {
      if (!skipFocusOnOpen) {
        modalRef.current?.focus()
      }

      modalRef.current?.scrollTo({ top: 0 })
    }
  }, [visible])

  const preventClickFromBubblingAndClosingModal = (event: MouseEvent) =>
    event.stopPropagation()

  const widthClassesBySize = {
    Large: { maxWidth: 'max-w-4xl', mobileWidth: 'w-full', width: 'md:w-3/5' },
    Medium: { maxWidth: 'max-w-3xl', mobileWidth: 'w-5/6', width: 'md:w-2/5' },
    Small: { maxWidth: 'max-w-2xl', mobileWidth: 'w-4/5', width: 'md:w-1/3' },
    Tiny: { maxWidth: 'max-w-xl', mobileWidth: 'w-3/5', width: 'md:w-1/4' },
  }

  const wrapperClassNames = classMerge(
    'fixed',
    'w-screen',
    'inset-0',
    'overflow-hidden',
    'z-modal',
    // Darken the background content to make the modal stand out
    'bg-neutral400/75',
    wrapperClassName,
  )

  const modalSizeClasses =
    size !== 'Full'
      ? [
          'border',
          'rounded-2xl',
          'max-h-[90vh]',
          'my-[8%]',
          'md:my-[5%]',
          'lg:my-[2%]',
          'h-auto',
          `${widthClassesBySize[size].maxWidth}`,
          `${widthClassesBySize[size].mobileWidth}`,
          `${widthClassesBySize[size].width}`,
        ]
      : ['h-full']

  const containerClassNames = classMerge(
    ...modalSizeClasses,
    'flex',
    'flex-col',
    'border-neutral100',
    'relative',
    'mx-auto',
    'bg-white',
    'overscroll-none',
    'overflow-hidden',
    className,
  )

  const bodyClassNames = classMerge(
    'overflow-auto rounded-none border-x-0 border-solid border-lightGrey500 px-4 pb-12',
    footer ? 'border-b' : 'border-b-0',
    title ? 'border-t' : 'border-t-0',
    bodyClassName,
  )

  return (
    <div
      className={wrapperClassNames}
      hidden={!visible}
      onClick={(e) => {
        preventClickFromBubblingAndClosingModal(e)
        if (!preventClose) {
          onClose && onClose()
        }
      }}
    >
      <div
        aria-label={'modal'}
        aria-modal='true'
        className={containerClassNames}
        data-testid='modal-content'
        hidden={!visible}
        onClick={preventClickFromBubblingAndClosingModal}
        ref={modalRef}
        role='dialog'
        tabIndex={0}
      >
        {/* Header */}
        {showHeader && (
          <section>
            <div
              className={classMerge(
                'flex min-h-12 flex-row items-center p-4',
                headerContainerClassName,
              )}
            >
              <div
                aria-label='Close'
                className={classMerge(
                  'absolute right-8 top-4 size-10 rounded-full bg-neutral200 hover:cursor-pointer',
                  closeContainerClassName,
                  preventClose ? 'hidden' : '',
                )}
                data-testid='modal-close'
                onClick={onClose}
                tabIndex={0}
              >
                <Icon
                  aria-label='Close'
                  className={'w-10 pt-3 text-dark'}
                  icon={faClose}
                />
              </div>
              {title && (
                <div
                  className={classMerge(
                    'mx-16 w-full text-center',
                    titleContainerClassName,
                  )}
                >
                  <Text
                    className={classMerge('text-dark', titleTextClassName)}
                    styleName={titleTextStyleName ?? 'p-strong'}
                    value={title}
                  />
                </div>
              )}
            </div>
          </section>
        )}

        {/* Body */}
        <section
          className={classMerge(
            'relative w-full overflow-auto',
            sectionClassName,
          )}
        >
          <div className={bodyClassNames}>{children}</div>
        </section>

        {/* Footer */}
        {footer && <section className={footerClassName}>{footer}</section>}
      </div>
    </div>
  )
}
