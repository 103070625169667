import type { BlockCamelCaseUTM, UTMParameters } from '@lib/browsers/utm/types'

export type QueryParameters =
  | ({ [key: string]: string } & UTMParameters & BlockCamelCaseUTM) // UTMParameters is the allow list of UTM parameters
  | string
  | URLSearchParams

export default function urlWithQueryParams(
  url: string,
  params?: QueryParameters,
): string {
  const paramString = new URLSearchParams(params).toString()
  return Boolean(paramString)
    ? `${url}${getQueryParamConnector(url)}${paramString}`
    : url
}

function getQueryParamConnector(url: string): string {
  return url.includes('?') ? '&' : '?'
}
