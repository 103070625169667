import { classMerge } from '@components/utilities/classMerge'
import type { ReactNode } from 'react'

// Most pages have a screen-xl desktop max-width, except the homepage with the larger screen-2xl.
// Some SSP pages support an even larger screen-3xl max-width.
export type PageWidth =
  | 'max-w-screen-lg'
  | 'max-w-screen-xl'
  | 'max-w-screen-2xl'
  | 'max-w-screen-3xl'
  | ''

/**
 * A wrapper component to help encourage a consistent responsive page margin throughout our app
 */
export const ResponsivePageMargin = ({
  children,
  className,
  contentWidth = 'max-w-screen-xl',
  unstyled = false,
}: {
  children: ReactNode
  className?: string
  contentWidth?: PageWidth
  unstyled?: boolean
}) => {
  return (
    <div
      className={classMerge(
        unstyled ? '' : 'mx-auto w-full px-4 md:px-8',
        contentWidth,
        className,
      )}
    >
      {children}
    </div>
  )
}

export const ignoreDefaultPageMargin = 'px-4 -mx-4 md:px-16 md:-mx-16'
